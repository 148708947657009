import { Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function BasementScreen({ prev, next }: ScreenProps) {
  const [basement, setBasement] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 5: </strong>Basement information
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Does your house have a basement? Is it heated?</Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={basement}
              onChange={(_, value) => {
                setBasement(value);
              }}
            >
              <ToggleButton value='heated'>Yes, heated</ToggleButton>
              <ToggleButton value='partially-heated'>Yes, partially heated</ToggleButton>
              <ToggleButton value='not-heated'>Yes, unheated</ToggleButton>
              <ToggleButton value='no'>No basement</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {(basement === "heated" || basement === "partially-heated" || basement === "no") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Is the house floor insulated?' picture='House_sections_floor_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Are the basement walls insulated?' picture='House_sections_basement_walls_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Is the basement floor insulated?' picture='House_sections_basement_floor_insulation.png' />
            </Grid>
          </>
        )}
        {(basement === "not-heated" || basement === "partially-heated") && (
          <>
            <Grid size={12}>
              <Divider />
            </Grid>
            <Grid size={12}>
              <InsulationComponent fadein question='Is the basement ceiling insulated?' picture='House_sections_basement_ceiling_insulation.png' />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Basement information' progressValue={41} content={content} />;
}
