import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid2";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SchnellcheckQuestionnaire } from "../../../../types/Schnellcheck";
import EnergyClasses from "../../../funnels/isfp/statusquo/EnergyClasses";
import HouseStatus from "../../../funnels/isfp/statusquo/HouseStatus";
import SavingsEstimates from "../../../funnels/isfp/statusquo/SavingsEstimates";
import { getDoorLabel, getHeatingLabel, getHouseTypeLabel, getWindowLabel, labelFromBoolean } from "../../../funnels/isfp/statusquo/utils";
import Typography from "@mui/material/Typography";

interface DisplaySchnellcheckDialogProps {
  schnellcheck: SchnellcheckQuestionnaire;
  open: boolean;
  handleClose: () => void;
}

export function DisplaySchnellcheckDialog({ schnellcheck, open, handleClose }: DisplaySchnellcheckDialogProps) {
  if (!schnellcheck) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ p: 2, backgroundColor: "#f6f6f6" }}>
        <DialogTitle>
          iSFP Schnellchek status & antworten
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              <EnergyClasses future={schnellcheck.report?.afterRenovationEnergyClass} current={schnellcheck.report?.currentEnergyClass} />
            </Grid>
            <Grid size={12}>
              <HouseStatus status={schnellcheck.report?.status} />
            </Grid>
            <Grid size={12}>
              <SavingsEstimates report={schnellcheck.report} />
            </Grid>
            <Grid size={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Informationen zum Haus
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Baujahr: {schnellcheck.answers.houseDetails?.yearOfConstruction}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Wohnfläche: {schnellcheck.answers.houseDetails?.area} m²
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Art: {getHouseTypeLabel(schnellcheck.answers.houseDetails!.type!)}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Dies ist ein denkmalgeschütztes Gebäude: {labelFromBoolean(schnellcheck.answers.houseDetails!.isMonument)}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Informationen zum Heizungsystem
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Heizsystem: {getHeatingLabel(schnellcheck.answers.houseHeating!.type!)}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Einbaujahr: {schnellcheck.answers.houseHeating!.yearOfInstalation!}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Verfügt Ihr Haus über Dämmung?
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Dach: {schnellcheck.answers.houseInsulation?.yearOfRoofInsulation ?? "--"}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Wände: {schnellcheck.answers.houseInsulation?.yearOfWallsInsulation ?? "--"}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Kellerdecke: {schnellcheck.answers.houseInsulation?.yearOfBasementInsulation ?? "--"}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant='overline' lineHeight={0} fontSize={11}>
                Nun benötigen wir bitte noch ein paar Angaben zu Fenstern und Türen
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Fenster: {getWindowLabel(schnellcheck.answers.houseOpenings?.windows) ?? "--"}
              </Typography>
              <Typography variant='body2' fontWeight={600}>
                Tür: {getDoorLabel(schnellcheck.answers.houseOpenings?.doors) ?? schnellcheck.answers.houseOpenings?.doorsYear ?? "--"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
