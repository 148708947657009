import { Box, Stack, Typography } from "@mui/material";
import { IsfpProcessStatus } from "../../../types/cockpit/types";
import Grid from "@mui/material/Grid2";
import React from "react";
import IsfpAcceptOffer from "./processes/IsfpAcceptOffer";
import IsfpFinished from "./processes/IsfpFinished";
import IsfpInvited from "./processes/IsfpInvited";
import IsfpOfferAccepted from "./processes/IsfpOfferAccepted";
import { RowDetailProps } from "./processes/types";
import IsfpRenovationAnswered from "./processes/IsfpRenovationAnswered";

/**
 * Detailed workflow view for iSFP documents
 */
export default function IsfpProcessDetails({ row, onChange }: RowDetailProps) {
  return (
    <Box sx={{ flexGrow: 1, m: 4 }}>
      <Stack>
        <Step isDone={true} label='Eingeladen'>
          <IsfpInvited row={row} />
        </Step>
        <Step
          isDone={IsfpProcessStatus.OrderedStatuses[row.status] >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.INVITE_ACCEPTED]}
          label='Einladung angenommen'
        >
          <IsfpAcceptOffer row={row} onChange={onChange} />
        </Step>
        <Step
          isDone={IsfpProcessStatus.OrderedStatuses[row.status] >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.OFFER_ACCEPTED]}
          label='Angebot akzeptiert'
        >
          <IsfpOfferAccepted row={row} />
        </Step>
        <Step
          isDone={IsfpProcessStatus.OrderedStatuses[row.status] >= IsfpProcessStatus.OrderedStatuses[IsfpProcessStatus.RENOVATION_QUESTIONNAIRE_ANSWERED]}
          label='iSFP in bearbeitung'
        >
          <IsfpRenovationAnswered row={row} onChange={onChange} />
        </Step>
        <Step isDone={row.status == IsfpProcessStatus.FINISHED} label='iSFP Dokument fertig'>
          <IsfpFinished row={row} />
        </Step>
      </Stack>
    </Box>
  );
}

interface StepProps {
  isDone: boolean;
  label: string;
  children?: React.ReactNode;
}

const Step = ({ isDone, label, children }: StepProps) => {
  return (
    <Grid container padding={0}>
      <Grid size={{ xs: 3 }}>
        <Milestone label={label} isDone={isDone} />
      </Grid>
      <Grid size={{ xs: 9 }} alignContent={"center"} padding={3}>
        {isDone && children}
      </Grid>
    </Grid>
  );
};

function Milestone({ label, isDone }: { label: string; isDone: boolean }) {
  return (
    <Typography
      sx={{
        height: "100%",
        borderLeftColor: isDone ? "primary.main" : "#E2E7E7",
        borderLeftWidth: isDone ? "0.2rem" : "0.1rem",
        borderLeftStyle: "solid",
        pl: "1rem",
        alignContent: "center",
        ml: isDone ? 0 : "0.05rem",
        fontSize: 12,
        fontWeight: isDone ? "fontWeightBold" : "fontWeightRegular",
        "&:before": {
          content: '""',
          display: "inline-block",
          width: 15,
          height: 15,
          borderRadius: 8,
          bgcolor: isDone ? "primary.main" : "#FBFBFB",
          border: isDone ? "" : "0.15rem solid #C6C7C8",
          position: "relative",
          left: isDone ? -25 : -24,
          verticalAlign: "middle",
        },
      }}
    >
      {label}
    </Typography>
  );
}
