import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { WallMaterials } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function OuterWallsScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 3: </strong>Outer walls information
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect
            label={"Material"}
            options={[
              WallMaterials.brick,
              WallMaterials.concrete,
              WallMaterials.sandLimeBrick,
              WallMaterials.hollowBrick,
              WallMaterials.wood,
              WallMaterials.halfWood,
            ]}
            value={""}
            required
          />
        </Grid>
        <Grid size={12}>
          <InsulationComponent question='Are these walls insulated?' picture='House_sections_outer_walls.png' />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Outer walls' progressValue={25} content={content} />;
}
