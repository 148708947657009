import { useCallback } from "react";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";
import { Lead } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/leads`;

export function useFetchLead() {
  const { authAxios, error, running, data } = useAuthAxios<Lead>();

  const getLead = useCallback(
    async (leadId: string) =>
      await authAxios({
        method: "GET",
        baseURL: `${API_ENDPOINT}/${leadId}`,
      }),
    [authAxios],
  );

  return { getLead, error, isLoading: running, lead: data };
}
