import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { PreferredHeating, RenovationPlans } from "./utils";
import NovoSelect from "../../../../components/common/NovoSelect";
import NovoMultiSelect from "../../../../components/common/NovoMultiSelect";
import FormScreen from "../FormScreen";

export default function CustomerPreferencesScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 9: </strong>Customer preferences
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect
            label={"Preferred heating"}
            options={[PreferredHeating.heatPump, PreferredHeating.pelletHeater, PreferredHeating.solar, PreferredHeating.disrictHeating]}
            value={""}
            required
          />
        </Grid>
        <Grid size={6}>
          <NovoMultiSelect
            label={"Renovation plans"}
            options={[RenovationPlans.exteriorWalls, RenovationPlans.roof, RenovationPlans.windows, RenovationPlans.fullRenovation]}
            value={[]}
          />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Customer Preferences' progressValue={75} content={content} />;
}
