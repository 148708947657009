import { Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { Ventilation, WaterHeating } from "./utils";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";

export default function HotWaterAndVentilationScreen({ prev, next }: ScreenProps) {
  const [waterBoiler, setWaterBoiler] = useState("");
  const [ventilation, setVentilation] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 7: </strong>Hot water and Ventilation information
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoSelect label={"Water heating"} options={[WaterHeating.sameAsHeating, WaterHeating.waterHeater, WaterHeating.gasBoiler]} value={""} required />
        </Grid>
        <Grid size={12}>
          <Stack spacing={2}>
            <Stack direction='row' alignItems='baseline' spacing={2}>
              <Typography>Do you have a water boiler installed?</Typography>
              <ToggleButtonGroup size='small' exclusive value={waterBoiler} onChange={(event, value) => setWaterBoiler(value)}>
                <ToggleButton value='yes'>Yes</ToggleButton>
                <ToggleButton value='no'>No</ToggleButton>
                <ToggleButton value='not-sure'>Not sure</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            {waterBoiler === "yes" && (
              <TextField
                fullWidth
                variant='outlined'
                id='water-boiler-capacity-input'
                label='Water boiler capacity'
                required={true}
                type='number'
                inputProps={{ min: 50, max: 1500 }}
              />
            )}
          </Stack>
        </Grid>
        <Grid size={6}>
          <NovoSelect
            label={"Ventilation"}
            options={[Ventilation.windows, Ventilation.fan, Ventilation.airConditioning, Ventilation.other]}
            value={ventilation}
            required
            onUpdate={(value) => setVentilation(value)}
          />
        </Grid>
        {ventilation === "Other" && (
          <Grid size={6}>
            <TextField fullWidth variant='outlined' id='other-ventilation-input' label='Other ventilation' required={true} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Hot waters & ventilation' progressValue={58} content={content} />;
}
