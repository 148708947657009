import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import NovoMultiImageUpload from "../../../../components/common/NovoMultiImageUpload";
import FormScreen from "../FormScreen";

export default function PhotoUploadScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 10: </strong>House photos upload (max. 4 images)
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoMultiImageUpload direction='column' maxImages={4} />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Photos upload' progressValue={83} content={content} />;
}
