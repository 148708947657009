import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";
import NovoSelect from "../../../components/common/NovoSelect";
import useFetchInviteEmailTemplate from "../../../hooks/useFetchInviteEmailTemplate";
import useFetchVoucherCount from "../../../hooks/useFetchVoucherCount";
import { leadErrorCodeToMessage, useCreateLead } from "../../../hooks/useLeadInviteHooks";
import { InviteEmailTemplate, Product } from "../../../types/cockpit/types";
import { isValidEmail } from "../../auth/emailValidator";
import { NovoTooltip } from "../../../components/common/NovoTooltip";
import { CCInviteClarification } from "./leadlist/CCInviteClarification";
import HelpIcon from "@mui/icons-material/Help";

interface InviteNewLeadDialogProps {
  onClose: () => void;
}

export default function InviteNewLeadDialog({ onClose }: InviteNewLeadDialogProps) {
  // const { user } = useContext(AuthContext);
  const [nameError, setNameError] = useState(false);
  const [name, setName] = useState("");
  // const [selfInvite, setSelfInvite] = useState(false);
  const [cc, setCc] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [product, setProduct] = useState("");
  const [productError, setProductError] = useState(false);
  const [free, setFree] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { createLead, error, isLoading, lead } = useCreateLead();
  const { getInviteEmailTemplate, template: fetchedTemplate } = useFetchInviteEmailTemplate();
  const [template, setTemplate] = useState<InviteEmailTemplate | undefined>();
  const [firstRenderReady, setFirstRenderReady] = useState(false);
  const { getVoucherCount, clearVoucherCount, count, hasVouchers } = useFetchVoucherCount();

  const productMapping = new Map<string, Product>();
  productMapping.set("Schnellcheck", Product.isfp);
  productMapping.set("Energieverbrauchausweis", Product.consumptionCertificate);
  productMapping.set("Sanierungsgutachten", Product.onePager);

  const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setCc(!!event.target.checked);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setErrorMessage("");
    setNameError(false);
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSuccess(false);
    setErrorMessage("");
    setEmailError(false);
    setEmail(event.target.value.toLowerCase());
  };

  const handleProductChange = (value: string) => {
    const product = productMapping.get(value);
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
    setProductError(false);
    setProduct(value);
    if (product) {
      getVoucherCount(product);
    }
  };

  // const selfInviteChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setEmail(user?.email ?? "");
  //     setName("Max Mustermann");
  //     setProduct("Schnellcheck");
  //     setSelfInvite(true);
  //     setFree(false);
  //   } else {
  //     setEmail("");
  //     setName("");
  //     setProduct("");
  //     setSelfInvite(false);
  //   }
  //   setShowSuccess(false);
  //   setErrorMessage("");
  // };

  const handleFreeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowSuccess(false);
    setErrorMessage("");
    setFree(checked);
  };

  const handleClose = () => {
    setName("");
    setNameError(false);
    setEmail("");
    setEmailError(false);
    setProduct("");
    setProductError(false);
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
    clearVoucherCount();
    onClose();
  };

  const handleSubmit = () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
      return;
    }
    if (!product) {
      setProductError(true);
      return;
    }
    const reqBody = {
      name,
      email,
      referrerProduct: {
        product: Product[productMapping.get(product)!],
        isFree: free,
      },
      cc,
    };
    setShowSuccess(false);
    setErrorMessage("");
    createLead(reqBody);
  };

  useEffect(() => {
    const selectedProduct = product ? Product[productMapping.get(product)!] : undefined;
    getInviteEmailTemplate(selectedProduct, free);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [free, getInviteEmailTemplate, product]);

  useEffect(() => {
    if (error) {
      setErrorMessage(leadErrorCodeToMessage(error.response?.data?.code));
    } else if (lead) {
      setShowSuccess(true);
      setName("");
      setEmail("");
      setProduct("");
    }
  }, [lead, error]);

  useEffect(() => {
    setFirstRenderReady(firstRenderReady || !!fetchedTemplate);
    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTemplate]);

  return (
    <Dialog open={firstRenderReady} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <Box data-cy='invite-lead-modal' sx={{ p: 2 }}>
        <DialogTitle>
          Neuen Kunden einladen
          <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose} data-cy='invite-lead-cancel-btn'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction='row'>
            <Stack spacing={2}>
              <DialogContentText>Bitte gib den Namen und die E-mail-Adresse des Kunden sowie das Produkt an, das du ihm empfehlen möchtest.</DialogContentText>
              {/* Commented out for the moment, as consultants were inviting themselves but landed in bubble as consultants, not homeowners */}
              {/* <Tooltip title={"Du kannst dich selbst einladen, um das Kundenerlebnis zu testen"}>
                <FormControlLabel
                  control={<Switch value={selfInvite} onChange={selfInviteChange} />}
                  label={
                    <>
                      Mich selbst einladen <InfoIcon fontSize='inherit' sx={{ verticalAlign: "middle" }} />
                    </>
                  }
                />
              </Tooltip> */}
              <FormControl fullWidth>
                <TextField
                  data-cy='invite-lead-name-input'
                  variant='outlined'
                  id='name'
                  value={name}
                  label='Name'
                  onChange={handleNameChange}
                  error={nameError}
                  helperText={nameError ? "Pflichtfeld!" : ""}
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  data-cy='invite-lead-email-input'
                  variant='outlined'
                  id='email'
                  type='email'
                  value={email}
                  label='E-mail'
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={!emailError ? "" : email ? "Bitte geben Sie eine gültige E-mail an" : "Pflichtfeld!"}
                  required
                />
              </FormControl>
              <FormControlLabel control={<Switch value={cc} onChange={handleCcChange} />} label='Kopie an meine E-Mail schicken' />
              <Stack direction='row' spacing={2} alignItems={"center"}>
                <NovoSelect
                  data-cy='invite-lead-product-selector'
                  label='Produkt'
                  options={[...productMapping.keys()]}
                  value={product}
                  onUpdate={handleProductChange}
                  error={productError}
                  helperText={productError ? "Pflichtfeld!" : ""}
                  required
                />
                {Product[productMapping.get(product)!] === Product.consumptionCertificate && (
                  <NovoTooltip title={<CCInviteClarification leadName={name} leadEmail={email} />}>
                    <HelpIcon />
                  </NovoTooltip>
                )}
              </Stack>
              {hasVouchers && Product[productMapping.get(product)!] !== Product.isfp && (
                <FormControlLabel
                  control={<Checkbox value={free} onChange={handleFreeChange} />}
                  label={`Es sind noch ${count} Gutscheine verfügbar. Möchtest du dem Kunden das Produkt kostenfrei anbieten?`}
                />
              )}
              <Fade in={showSuccess} timeout={500} unmountOnExit>
                <Alert data-cy='success-invite-dialog' sx={{ marginTop: 2 }} severity='success'>
                  Das hat geklappt!
                </Alert>
              </Fade>
              <Fade in={!!errorMessage} timeout={500} unmountOnExit>
                <Alert sx={{ marginTop: 2 }} severity='error'>
                  {errorMessage}
                </Alert>
              </Fade>
              <DialogActions sx={{ p: 3 }}>
                <ButtonWithSpinner data-cy='invite-lead-confirm-btn' variant={"contained"} loading={isLoading} label='Einladen' onClick={handleSubmit} />
              </DialogActions>
            </Stack>
            {template?.emailMessage && <div dangerouslySetInnerHTML={{ __html: template?.emailMessage.replace("{name}", name) }} />}
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
