import { Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import NovoFileUpload from "../../../../components/common/NovoFileUpload";
import FormScreen from "../FormScreen";

export default function PlansAndDocumentsUploadScreen({ prev, next }: ScreenProps) {
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 11: </strong>House plans & documents upload
          </Typography>
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='House plans (max. 4)' accept='.jpg,.jpeg,.png,.pdf' />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Drawings (max. 4)' accept='.jpg,.jpeg,.png,.pdf' />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Other docs (max. 4)' accept='*/*' />
        </Grid>
        <Grid size={12}>
          <NovoFileUpload multiple label='Energy certificate (pdf)' maxFiles={1} />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Plans upload' progressValue={91} content={content} />;
}
