export enum Steps {
  start = "",
  general = "#general",
  floors = "#floors",
  outerWalls = "#outer-walls",
  attic = "#attic",
  basement = "#basement",
  doorsWindows = "#doors-windows",
  hotWaterVentilation = "#hot-water-ventilation",
  photovoltaics = "#photovoltaics",
  customerPreferences = "#customer-preferences",
  photoUpload = "#photo-upload",
  plansAndDocumentsUpload = "#plans-and-documents-upload",
  final = "#final",
}

export const fromHash = (value: string): Steps => {
  switch (value) {
    case "":
      return Steps.start;
    case "#general":
      return Steps.general;
    case "#floors":
      return Steps.floors;
    case "#outer-walls":
      return Steps.outerWalls;
    case "#attic":
      return Steps.attic;
    case "#basement":
      return Steps.basement;
    case "#doors-windows":
      return Steps.doorsWindows;
    case "#hot-water-ventilation":
      return Steps.hotWaterVentilation;
    case "#photovoltaics":
      return Steps.photovoltaics;
    case "#customer-preferences":
      return Steps.customerPreferences;
    case "#photo-upload":
      return Steps.photoUpload;
    case "#plans-and-documents-upload":
      return Steps.plansAndDocumentsUpload;
    case "#final":
      return Steps.final;
    default:
      throw new Error(`"${value}" is not a valid value for Steps.`);
  }
};

export enum WallMaterials {
  brick = "Brick",
  concrete = "Concrete",
  wood = "Wooden frame",
  sandLimeBrick = "Sand-lime brick",
  hollowBrick = "Hollow brick",
  halfWood = "Half-wooden frame",
}

export enum InsulationMaterials {
  mineralWool = "Mineral wool",
  stoneWool = "Stone wool",
  styrofoam = "Styrofoam",
  woodFiber = "Wood fiber",
}

export enum FrameMaterial {
  wood = "Wood",
  metal = "Metal",
  plastic = "Plastic",
}

export enum WaterHeating {
  sameAsHeating = "Same as heating",
  waterHeater = "Water heater",
  gasBoiler = "Gas boiler",
}

export enum Ventilation {
  windows = "Windows",
  fan = "Fan",
  airConditioning = "Air conditioning",
  other = "Other",
}

export enum PreferredHeating {
  heatPump = "Heat pump",
  pelletHeater = "Pellet heater",
  solar = "Solar",
  disrictHeating = "District heating",
}

export enum RenovationPlans {
  exteriorWalls = "Exterior walls",
  roof = "Roof",
  windows = "Windows",
  fullRenovation = "Full KFW renovation",
}
