import { Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { FrameMaterial } from "./utils";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import FormScreen from "../FormScreen";

export default function DoorsAndWindowsScreen({ prev, next }: ScreenProps) {
  const [multiWindowTypes, setMultiWindowTypes] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 6: </strong>Doors & windows information
          </Typography>
        </Grid>
        <Grid size={6}>
          <NovoSelect label={"Window frame material"} options={[FrameMaterial.wood, FrameMaterial.metal, FrameMaterial.plastic]} value={""} required />
        </Grid>
        <Grid size={6}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Do you have other types of windows?</Typography>
            <ToggleButtonGroup size='small' exclusive value={multiWindowTypes} onChange={(event, value) => setMultiWindowTypes(value)}>
              <ToggleButton value='yes'>Yes</ToggleButton>
              <ToggleButton value='no'>No</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid size={6}>
          <TextField
            fullWidth
            variant='outlined'
            id='windows-installation-year-input'
            label='Windows installation year'
            required={true}
            type='number'
            inputProps={{ min: 1900, max: new Date().getFullYear() }}
          />
        </Grid>
        <Grid size={6}>
          <NovoSelect label={"Door frame material"} options={[FrameMaterial.wood, FrameMaterial.metal, FrameMaterial.plastic]} value={""} required />
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Doors & windows' progressValue={50} content={content} />;
}
