import { Box, Typography } from "@mui/material";
import { RowDetailProps } from "./types";
import { useFetchIsfpDocumentUrl } from "../../../../hooks/useFetchIsfpDocument";
import { useEffect } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";

export default function IsfpFinished({ row }: RowDetailProps) {
  const { fetchIsfpDocumentUrl, isfpDocumentLink, fetchDocumentRunning } = useFetchIsfpDocumentUrl();

  const openPdfDoc = () => {
    fetchIsfpDocumentUrl(row.id);
  };

  useEffect(() => {
    if (isfpDocumentLink) {
      window.location.href = isfpDocumentLink.url;
    }
  }, [isfpDocumentLink]);

  return (
    <Typography>
      iSFP Dokument ist fertig
      <Box display={"inline"} pl={2}>
        <ButtonWithSpinner variant='outlined' color='secondary' loading={fetchDocumentRunning} label='Dokument herunterladen' onClick={openPdfDoc} />
      </Box>
    </Typography>
  );
}
