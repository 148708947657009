import Grid from "@mui/material/Grid2";
import { ScreenProps } from "./RenovationsFunnel";
import { Button, Grow, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import FormScreen from "../FormScreen";
import { useState } from "react";
import useImage from "../../../../hooks/useImage";
import CloseIcon from "@mui/icons-material/Close";

export default function FloorsScreen({ prev, next }: ScreenProps) {
  const [storeys, setStoreys] = useState("");
  const [defaultFloorHeight, setDefaultFloorHeight] = useState("");
  const [typed, setTyped] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const valueAccepted = () => {
    if (!storeys || !defaultFloorHeight) return;
    if (timeoutId) clearTimeout(timeoutId);
    const timeout = setTimeout(() => setTyped(true), 800);
    setTimeoutId(timeout);
  };
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 2: </strong>Storeys and floor information
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            fullWidth
            value={storeys}
            variant='outlined'
            id='number-of-floors-input'
            label='Storeys'
            required={true}
            type='number'
            inputProps={{ min: 1, max: 20 }}
            onChange={(e) => {
              setStoreys(e.target.value);
            }}
          />
        </Grid>
        {!typed && (
          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              value={defaultFloorHeight}
              variant='outlined'
              id='floor-height-input'
              label='Floor height'
              required={true}
              type='number'
              inputProps={{ min: 150, max: 400 }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
              }}
              onChange={(e) => {
                setDefaultFloorHeight(e.target.value);
                valueAccepted();
              }}
            />
          </Grid>
        )}
        {typed && (
          <Grid size={12}>
            <FloorsDiagram storeys={+storeys} floorHeight={defaultFloorHeight} key={defaultFloorHeight} />
          </Grid>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Storeys' progressValue={16} content={content} />;
}

function FloorsDiagram({ storeys, floorHeight }: { storeys: number; floorHeight: string }) {
  const [groundFloorHeight, setGroundFloorHeight] = useState(floorHeight);
  const [upperFloorsHeight, setUpperFloorsHeight] = useState(floorHeight);
  const [hasAttic, setHasAttic] = useState(false);
  const [hasBasement, setHasBasement] = useState(false);
  const [atticHeight, setAtticHeight] = useState("");
  const [basementHeight, setBasementHeight] = useState("");
  const roof = useImage("House_sections_roof.png");
  const attic = useImage("House_sections_attic.png");
  const upperFloors = useImage("House_sections_upper_floors.png");
  const groundFloor = useImage("House_sections_ground_floor.png");
  const ground = useImage("House_sections_ground.png");
  const basement = useImage("House_sections_basement.png");

  const toggleAttic = () => {
    if (hasAttic) {
      setAtticHeight("");
    } else {
      setAtticHeight(upperFloorsHeight || groundFloorHeight);
    }
    setHasAttic(!hasAttic);
  };
  const toggleBasement = () => {
    if (hasBasement) {
      setBasementHeight("");
    } else {
      setBasementHeight(upperFloorsHeight || groundFloorHeight);
    }
    setHasBasement(!hasBasement);
  };
  return (
    <Stack p={2}>
      <Grid container alignItems='center' justifyContent='center'>
        {!hasAttic && (
          <>
            <Grid size={6}>
              <Grow in={!hasAttic} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={roof.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={!hasAttic} timeout={800}>
                <Button variant='outlined' color='secondary' onClick={toggleAttic}>
                  Add attic
                </Button>
              </Grow>
            </Grid>
          </>
        )}
        {hasAttic && (
          <>
            <Grid size={6}>
              <Grow in={hasAttic} timeout={400}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={attic.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={hasAttic} timeout={400}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={atticHeight}
                    variant='outlined'
                    id='attic-height-input'
                    label='Height'
                    required={true}
                    type='number'
                    inputProps={{ min: 150, max: 400 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                    }}
                    onChange={(e) => {
                      setAtticHeight(e.target.value);
                    }}
                  />
                  <IconButton color='secondary' onClick={toggleAttic}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        {storeys > 1 && (
          <>
            <Grid size={6}>
              <Grow in={storeys > 1} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={upperFloors.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={storeys > 1} timeout={800}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={upperFloorsHeight}
                    variant='outlined'
                    id='upper-floors-height-input'
                    label='Height'
                    required={true}
                    type='number'
                    inputProps={{ min: 150, max: 400 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                    }}
                    onChange={(e) => {
                      setUpperFloorsHeight(e.target.value);
                    }}
                  />
                  <div style={{ width: 46 }} />
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        <Grid size={6}>
          <Grow in timeout={800}>
            <Stack alignItems='center' flexGrow={1}>
              <img src={groundFloor.image} height={69} style={{ alignSelf: "center" }} />
            </Stack>
          </Grow>
        </Grid>
        <Grid size={6}>
          <Grow in timeout={800}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <TextField
                fullWidth
                value={groundFloorHeight}
                variant='outlined'
                id='ground-floor-height-input'
                label='Height'
                required={true}
                type='number'
                inputProps={{ min: 150, max: 400 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
                onChange={(e) => {
                  setGroundFloorHeight(e.target.value);
                }}
              />
              <div style={{ width: 46 }} />
            </Stack>
          </Grow>
        </Grid>
        {!hasBasement && (
          <>
            <Grid size={6}>
              <Grow in={!hasBasement} timeout={800}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={ground.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={!hasBasement} timeout={800}>
                <Button variant='outlined' color='secondary' onClick={toggleBasement}>
                  Add basement
                </Button>
              </Grow>
            </Grid>
          </>
        )}
        {hasBasement && (
          <>
            <Grid size={6}>
              <Grow in={hasBasement} timeout={400}>
                <Stack alignItems='center' flexGrow={1}>
                  <img src={basement.image} height={69} style={{ alignSelf: "center" }} />
                </Stack>
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in={hasBasement} timeout={400}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <TextField
                    fullWidth
                    value={basementHeight}
                    variant='outlined'
                    id='basement-height-input'
                    label='Height'
                    required={true}
                    type='number'
                    inputProps={{ min: 150, max: 400 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                    }}
                    onChange={(e) => {
                      setBasementHeight(e.target.value);
                    }}
                  />
                  <IconButton color='secondary' onClick={toggleBasement}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
}
