import { useCallback } from "react";
import { apiEndpoint } from "../utils/utils";
import { useAuthAxios } from "./useAuthAxios";
import { IsfpProcess } from "../types/cockpit/types";

export const API_ENDPOINT = `${apiEndpoint()}/isfps/offer-acceptance`;

export type SetOfferAcceptedRequest = {
  isfpProcessId: string;
};

export default function useSetOfferAccepted() {
  const { authAxios, error, running, data } = useAuthAxios<IsfpProcess>();

  const postSetOfferAccepted = useCallback(
    async (request: SetOfferAcceptedRequest) =>
      await authAxios({
        method: "POST",
        baseURL: `${API_ENDPOINT}`,
        data: JSON.stringify(request),
      }),
    [authAxios],
  );

  return { postSetOfferAccepted, error, running, data };
}
