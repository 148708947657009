import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import { useFetchLead } from "../../../../hooks/useFetchLead";
import { useFetchSchnellcheck } from "../../../../hooks/useFetchSchnellcheck";
import useSetOfferAccepted from "../../../../hooks/useSetOfferAccepted";
import { IsfpProcessStatus } from "../../../../types/cockpit/types";
import { DisplayBubbleFlowSchnellcheckDialog } from "../../leads/leadlist/DisplayBubbleFlowSchnellcheckDialog";
import { DisplaySchnellcheckDialog } from "../../leads/leadlist/DisplaySchnellcheckDialog";
import { RowDetailProps } from "./types";

export default function IsfpAcceptOffer({ row, onChange }: RowDetailProps) {
  const { postSetOfferAccepted, running, data: isfpProcess } = useSetOfferAccepted();
  const { getSchnellcheck, isLoading: isLoadingSchnellcheck, schnellcheck } = useFetchSchnellcheck();
  const [isSchnellcheckOpen, setSchnellcheckOpen] = useState(false);
  const { getLead, isLoading: isLoadingLead, lead } = useFetchLead();
  const [isBubbleSchnellCheckOpen, setBubbleSchnellCheckOpen] = useState(false);

  const toggleSchnellcheckModal = () => {
    setSchnellcheckOpen(!isSchnellcheckOpen);
  };

  const toggleBubbleSchnellCheckModal = () => {
    setBubbleSchnellCheckOpen(!isBubbleSchnellCheckOpen);
  };

  function setOfferAccepted() {
    if (!running) {
      postSetOfferAccepted({ isfpProcessId: row.id });
    }
  }

  const openQuestionnaireDetails = async (questionnaireId?: string) => {
    if (!questionnaireId) {
      return;
    }
    getSchnellcheck(questionnaireId);
  };

  const openBubbleQuestionnaireDetails = async (leadId?: string) => {
    if (!leadId) {
      return;
    }
    getLead(leadId);
  };

  useEffect(() => {
    if (isfpProcess && onChange) {
      onChange(isfpProcess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isfpProcess]);

  useEffect(() => {
    setSchnellcheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schnellcheck]);

  useEffect(() => {
    setBubbleSchnellCheckOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  return (
    <Box>
      <Typography>
        Schnellcheck wurde ausgefüllt
        {row.schnellCheckId && (
          <Box display={"inline"} pl={2}>
            <ButtonWithSpinner
              variant='outlined'
              color='secondary'
              loading={isLoadingSchnellcheck}
              label='Antworten ansehen'
              onClick={() => openQuestionnaireDetails(row.schnellCheckId)}
            />
          </Box>
        )}
        {IsfpProcessStatus.INVITE_ACCEPTED && !row.schnellCheckId && (
          <Box display={"inline"} pl={2}>
            <ButtonWithSpinner
              variant='outlined'
              color='secondary'
              loading={isLoadingLead}
              label='Antworten ansehen'
              onClick={() => openBubbleQuestionnaireDetails(row.leadId)}
            />
          </Box>
        )}
      </Typography>
      {row.status == IsfpProcessStatus.INVITE_ACCEPTED && (
        <Box pt={2}>
          <Typography>
            Angebot wird dem Kunden (vorläufig manuell) unterbreitet
            <Box display={"inline"} pl={2}>
              <ButtonWithSpinner
                data-cy={`set-offer-accepted-bnt-${row.leadEmail}`}
                variant={"contained"}
                loading={running}
                label='Angebot wurde vom Kunden akzeptiert'
                onClick={setOfferAccepted}
              />
            </Box>
          </Typography>
        </Box>
      )}
      <DisplaySchnellcheckDialog schnellcheck={schnellcheck!} open={isSchnellcheckOpen} handleClose={toggleSchnellcheckModal} />
      <DisplayBubbleFlowSchnellcheckDialog
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bubbleFlow={lead?.properties?.find((property) => property.isfp?.bubbleFlow)?.isfp?.bubbleFlow!}
        open={isBubbleSchnellCheckOpen}
        handleClose={toggleBubbleSchnellCheckModal}
      />
    </Box>
  );
}
