import { Grow, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import NovoSelect from "../../../../components/common/NovoSelect";
import { InsulationMaterials } from "./utils";
import useImage from "../../../../hooks/useImage";

type InsulationComponentProps = {
  question: string;
  picture: string;
  fadein?: boolean;
};

export default function InsulationComponent({ question, picture, fadein = false }: InsulationComponentProps) {
  const [insulated, setInsulated] = useState("");
  const pictogram = useImage(picture);
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        lg: 4,
      }}
    >
      <Grid size={12}>
        <Grow in timeout={fadein ? 800 : 0}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              {question}
            </Typography>
            <ToggleButtonGroup size='small' exclusive value={insulated} onChange={(event, value) => setInsulated(value)} sx={{ flexGrow: 1 }}>
              <ToggleButton value='yes'>Yes</ToggleButton>
              <ToggleButton value='no'>No</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grow>
      </Grid>
      <Grid size={6}>
        <Grow in timeout={fadein ? 800 : 0}>
          <Stack alignItems='center' flexGrow={1}>
            <img src={pictogram.image} width={150} height={150} style={{ alignSelf: "center" }} />
          </Stack>
        </Grow>
      </Grid>
      <Grid size={6}>
        {insulated === "yes" && (
          <Grow in timeout={800}>
            <Stack spacing={2} flexGrow={1}>
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-year-input'
                label='Insulation year'
                required={true}
                type='number'
                inputProps={{ min: 1900, max: new Date().getFullYear() }}
              />
              <TextField
                fullWidth
                variant='outlined'
                id='insulation-thickness-input'
                label='Insulation thickness'
                type='number'
                inputProps={{ min: 1, max: 25 }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
                }}
              />
              <NovoSelect
                label={"Insulation material"}
                options={[InsulationMaterials.mineralWool, InsulationMaterials.stoneWool, InsulationMaterials.styrofoam, InsulationMaterials.woodFiber]}
                value={""}
              />
            </Stack>
          </Grow>
        )}
      </Grid>
    </Grid>
  );
}
