import AssessmentIcon from "@mui/icons-material/Assessment";
import BoltIcon from "@mui/icons-material/Bolt";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Divider, ListItemButton, ListItemIcon } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Contexts";
import { isAdmin, isBankManager } from "../../utils/utils";
import OutsideClickHandler from "react-outside-click-handler";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";

export default function SideMenu() {
  const { logout } = useContext(AuthContext);
  const [isExpanded, setExpanded] = useState(false);
  const { user } = useContext(AuthContext);

  const toggleExpandedMenu = () => {
    setExpanded(!isExpanded);
  };

  const adminOptions = () => {
    return (
      <>
        <Divider sx={{ p: 1, mb: "1rem" }} />
        <ListItem sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/cockpit/metrics' onClick={() => setExpanded(false)}>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary='Metriken' />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/cockpit/consumption-certificates' onClick={() => setExpanded(false)}>
            <ListItemIcon>
              <BoltIcon />
            </ListItemIcon>
            <ListItemText primary='Verbrauchsausweis' />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/cockpit/isfps' onClick={() => setExpanded(false)}>
            <ListItemIcon>
              <EnergySavingsLeafIcon />
            </ListItemIcon>
            <ListItemText primary='iSFP' />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/cockpit/one-pager' onClick={() => setExpanded(false)}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary='Sanierungsgutachten' />
          </ListItemButton>
        </ListItem>
      </>
    );
  };

  const bankManagerOptions = () => {
    return (
      <>
        <Divider sx={{ p: 1, mb: "1rem" }} />
        <ListItem sx={{ p: 0 }}>
          <ListItemButton component={Link} to='/cockpit/consultants' onClick={() => setExpanded(false)}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary='Team-Übersicht' />
          </ListItemButton>
        </ListItem>
      </>
    );
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isExpanded) {
          setExpanded(false);
        }
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          position: "fixed",
          borderRadius: 0,
          height: "calc(100vh - 64px)",
          width: isExpanded ? "264px" : "56px",
          overflow: "hidden",
          textWrap: "nowrap",
          zIndex: 10,
          transition: "width 0.3s ease-in-out",
        }}
      >
        <List sx={{ width: "100%" }}>
          <ListItem sx={{ paddingTop: "1rem", paddingBottom: 0, px: 0 }}>
            <ListItemButton onClick={toggleExpandedMenu}>
              <ListItemIcon>{isExpanded ? <KeyboardArrowLeftIcon /> : <ChevronRightIcon />}</ListItemIcon>
              <ListItemText primary='Ausblenden' />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ p: 1, mb: "1rem" }} />
          <ListItem sx={{ p: 0 }}>
            <ListItemButton component={Link} to='/cockpit/leads' onClick={() => setExpanded(false)}>
              <ListItemIcon>
                <LeaderboardIcon />
              </ListItemIcon>
              <ListItemText primary='Meine Kunden' />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <ListItemButton component={Link} to='/cockpit/invites' onClick={() => setExpanded(false)}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary='Meine Einladungen' />
            </ListItemButton>
          </ListItem>
          {(isBankManager(user) || isAdmin(user)) && bankManagerOptions()}
          {isAdmin(user) && adminOptions()}
          <ListItem sx={{ p: 0, position: "absolute", bottom: "1rem", width: "100%" }}>
            <ListItemButton data-cy='logout-btn' onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='Abmelden' />
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </OutsideClickHandler>
  );
}
