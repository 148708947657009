import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { ChangeEvent, useEffect, useState } from "react";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";
import useFetchVoucherCount from "../../../../hooks/useFetchVoucherCount";
import { leadErrorCodeToMessage, useInviteLeadProduct } from "../../../../hooks/useLeadInviteHooks";
import { InviteEmailTemplate, Product } from "../../../../types/cockpit/types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CCInviteClarification } from "./CCInviteClarification";
import { NovoTooltip } from "../../../../components/common/NovoTooltip";
import HelpIcon from "@mui/icons-material/Help";
import useFetchInviteEmailTemplate from "../../../../hooks/useFetchInviteEmailTemplate";

interface InviteLeadToProductDialogProps {
  invite: { leadId: string; leadName: string; leadEmail: string; product: Product };
  onClose: () => void;
}

export const InviteLeadToProductDialog = ({ invite, onClose }: InviteLeadToProductDialogProps) => {
  const [free, setFree] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [firstRenderReady, setFirstRenderReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { inviteLeadToProduct, error, isLoading, inviteResponse } = useInviteLeadProduct();
  const { getVoucherCount, count, isLoading: isVoucherCountLoading, hasVouchers } = useFetchVoucherCount();
  const { getInviteEmailTemplate, template: fetchedTemplate } = useFetchInviteEmailTemplate();
  const [template, setTemplate] = useState<InviteEmailTemplate | undefined>();

  const productMapping = new Map<Product, string>();
  productMapping.set(Product.isfp, "Schnellcheck");
  productMapping.set(Product.consumptionCertificate, "Energieverbrauchsausweis");
  productMapping.set(Product.onePager, "Sanierungsgutachten");

  useEffect(() => {
    if (!invite) {
      return;
    }
    getVoucherCount(invite.product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  useEffect(() => {
    getInviteEmailTemplate(invite.product, free);
  }, [free, getInviteEmailTemplate, invite]);

  useEffect(() => {
    setFirstRenderReady(firstRenderReady || !!fetchedTemplate);
    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTemplate]);

  const handleFreeChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowSuccess(false);
    setErrorMessage("");
    setFree(checked);
  };

  const handleClose = () => {
    onClose();
    setFree(false);
    setShowSuccess(false);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    if (!invite) {
      return;
    }
    const reqBody = {
      product: invite.product,
      isFree: free,
    };
    setErrorMessage("");

    inviteLeadToProduct(invite.leadId, reqBody);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(leadErrorCodeToMessage(error.response?.data?.code));
    } else if (inviteResponse) {
      setShowSuccess(true);
    }
  }, [inviteResponse, error]);

  return (
    <Dialog open={firstRenderReady} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <Box sx={{ p: 2 }}>
        {invite && (
          <>
            <DialogTitle>
              <span style={{ maxWidth: "80%", display: "inline-block" }}>Kunden zum {productMapping.get(invite.product)} einladen</span>
              <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} direction='row'>
                <Stack spacing={2}>
                  <Stack direction='row'>
                    <DialogContentText>
                      Lade {invite.leadName} zum {productMapping.get(invite.product)} ein.&nbsp;
                    </DialogContentText>
                    {invite.product === Product.consumptionCertificate && (
                      <NovoTooltip title={<CCInviteClarification {...invite} />}>
                        <HelpIcon />
                      </NovoTooltip>
                    )}
                  </Stack>
                  {hasVouchers && invite.product !== Product.isfp && (
                    <FormControlLabel
                      disabled={showSuccess || !open}
                      control={<Checkbox value={free} onChange={handleFreeChange} />}
                      label={`Du hast noch ${count} Gutscheine übrig. Möchtest du dem Kunden ein kostenloses Angebot unterbreiten?`}
                    />
                  )}
                  <Fade in={showSuccess} timeout={500} unmountOnExit>
                    <Alert sx={{ marginTop: 2 }} severity='success'>
                      Erfolg!
                    </Alert>
                  </Fade>
                  <Fade in={!!errorMessage} timeout={500} unmountOnExit>
                    <Alert sx={{ marginTop: 2 }} severity='error'>
                      {errorMessage}
                    </Alert>
                  </Fade>
                  <DialogActions sx={{ p: 3 }}>
                    <ButtonWithSpinner
                      disabled={!open || showSuccess || isVoucherCountLoading}
                      variant={"contained"}
                      loading={isLoading}
                      label='Einladen'
                      onClick={handleSubmit}
                    />
                  </DialogActions>
                </Stack>
                {template?.emailMessage && <div dangerouslySetInnerHTML={{ __html: template?.emailMessage.replace("{name}", invite.leadName) }} />}
              </Stack>
            </DialogContent>
          </>
        )}
      </Box>
    </Dialog>
  );
};
