import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";
import InsulationComponent from "./InsulationComponent";

export default function AtticScreen({ prev, next }: ScreenProps) {
  const [attic, setAttic] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 4: </strong>Attic information
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              Does your house have an attic? Is it heated?
            </Typography>
            <ToggleButtonGroup
              size='small'
              exclusive
              value={attic}
              onChange={(_, value) => {
                setAttic(value);
              }}
              sx={{ flexGrow: 1 }}
            >
              <ToggleButton value='heated'>Yes, heated</ToggleButton>
              <ToggleButton value='not-heated'>Yes, unheated</ToggleButton>
              <ToggleButton value='no'>No attic</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {(attic === "heated" || attic === "no") && (
          <Grid size={12}>
            <InsulationComponent fadein question='Is the roof insulated?' picture='House_sections_roof_insulation.png' />
          </Grid>
        )}
        {attic === "not-heated" && (
          <Grid size={12}>
            <InsulationComponent fadein question='Is the ceiling insulated?' picture='House_sections_ceiling_insulation.png' />
          </Grid>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Attic information' progressValue={33} content={content} />;
}
