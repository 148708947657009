import Grid from "@mui/material/Grid2";
import ProgressBar from "../ProgressBar";
import { Button, Stack, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";

export default function FinalScreen({ next }: ScreenProps) {
  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mx: { xs: 0, lg: 10 },
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={"All done!"} value={100} />
      </Grid>
      <Grid size={12}>
        <Stack>
          <Typography variant='h4' textAlign='center'>
            Thank you!
          </Typography>
          <Typography mt={8}>You are all done and we will now process your answers.</Typography>
          <Typography mt={2}>
            Someone from team NOVO will reach out to you in the following days to schedule a visit to the property, a necessary step before we can issue the
            iSFP certificate.
          </Typography>
          <Stack direction='row' spacing={2} sx={{ mt: 8, justifyContent: "center" }}>
            <Button variant='contained' onClick={next}>
              Restart
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
