import { AuthenticationDetails, ChallengeName, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { UserResponse } from "../types/cockpit/types";
import { apiEndpoint } from "../utils/utils";
import Smartlook from "smartlook-client";

const cognitoTokenLSName = "cognitoken";

export const cognitoClientId = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_AUTH_IDP ? JSON.parse(process.env.REACT_APP_CUSTOM_AUTH_IDP).clientId : "282tqvsjj66idq4igbdqslu9pb";
    case "app-stg.novo.eco":
      return "2kr3rri2k4jl0gorraa2feha2q";
    case "app.novo.eco":
      return "7i4r7dn6d62em7qdmg5ipiheii";
    default:
      throw new Error("hostname not recognised");
  }
};

export const cognitoPoolId = () => {
  switch (window.location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "app-dev.novo.eco":
      return process.env.REACT_APP_CUSTOM_AUTH_IDP ? JSON.parse(process.env.REACT_APP_CUSTOM_AUTH_IDP).poolId : "eu-central-1_kbc77Yl3M";
    case "app-stg.novo.eco":
      return "eu-central-1_ZIHPkxqrO";
    case "app.novo.eco":
      return "eu-central-1_o0g7ETcpc";
    default:
      throw new Error("hostname not recognised");
  }
};

export const userPool = new CognitoUserPool({ ClientId: cognitoClientId(), UserPoolId: cognitoPoolId() });

const API_ENDPOINT = `${apiEndpoint()}/user`;

export enum AuthErrorType {
  FAILED_FETCHING_USER,
  ACCOUNT_CREATION_USERNAME_ALREADY_EXISTS,
  FAILED_AUTHENTICATING,
  WRONG_CREDENTIALS,
  FAILED_ALREADY_CHANGED_PASSWORD,
  FAILED_CHANGING_PASSWORD,
  CURRENT_PASSWORD_IS_CORRECT,
  MISSING_MANDATORY_PASSWORD_CHANGE_DATA,
  FAILED_CREATING_NEW_USER,
  FAILED_CONFIRMING_ACCOUNT,
  FAILED_CONFIRMING_ACCOUNT_WRONG_CODE,
  FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED,
  ACCOUNT_CREATION_INVALID_PARAMETER,
  FAILED_SENDING_FORGOT_PASSWORD_CODE,
  FAILED_RESETTING_PASSWORD,
  FAILED_RESET_PASSWORD_INCORRECT_CODE,
  FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT,
  FAILED_RESET_PASSWORD_LIMIT_EXCEEDED,
  FAILED_RESET_PASSWORD_EXPIRED_CODE,
}

export const authErrorTypeToErrorText = (errorType: AuthErrorType) => {
  switch (errorType) {
    case AuthErrorType.MISSING_MANDATORY_PASSWORD_CHANGE_DATA:
      return "Die Daten für die obligatorische Passwortänderung fehlen. Bitte versuche es erneut mit dem Login.";
    case AuthErrorType.FAILED_FETCHING_USER:
      return "Deine Benutzerdaten konnten nicht aus unserem System abgerufen werden.";
    case AuthErrorType.FAILED_AUTHENTICATING:
      return "Authentifizierungsfehler.";
    case AuthErrorType.FAILED_CHANGING_PASSWORD:
      return "Wegen eines technischen Fehlers konnte dein Passwort nicht geändert werden.";
    case AuthErrorType.FAILED_ALREADY_CHANGED_PASSWORD:
      return "Dein Passwort wurde bereits geändert.";
    case AuthErrorType.CURRENT_PASSWORD_IS_CORRECT:
      return "Dein aktuelles Passwort stimmt schon, keine Änderungen sind nötig";
    case AuthErrorType.WRONG_CREDENTIALS:
      return "Dein Benutzername oder dein Passwort stimmen nicht.";
    case AuthErrorType.ACCOUNT_CREATION_USERNAME_ALREADY_EXISTS:
      return "Dieser Benutzername existiert bereits. Bitte versuche dich anzumelden.";
    case AuthErrorType.FAILED_CREATING_NEW_USER:
      return "Das Erstellen deines neuen Accounts ist fehlgeschlagen. Bitte versuche es erneut oder kontaktiere uns.";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT:
      return "Fehler bei der Bestäting deines Accounts.";
    case AuthErrorType.ACCOUNT_CREATION_INVALID_PARAMETER:
      return "Einer der Eingabewerte ist nicht korrekt. Bitte versuche es erneut";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT_WRONG_CODE:
      return "Ungültiger Bestätigungscode eingegeben.";
    case AuthErrorType.FAILED_CONFIRMING_ACCOUNT_POSSIBLY_ALREADY_CONFIRMED:
      return "Fehler: Dein Account ist wahrscheinlich bereits aktiviert.";
    case AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE:
      return "Es ist ein Fehler aufgetreten. Bitte versuche es erneut.";
    case AuthErrorType.FAILED_RESETTING_PASSWORD:
      return "Fehler beim Ändern deines Passworts. Bitte versuche es erneut.";
    case AuthErrorType.FAILED_RESET_PASSWORD_INCORRECT_CODE:
      return "Ungültiger Reset-Code eingegeben.";
    case AuthErrorType.FAILED_SENDING_FORGOT_PASSWORD_CODE_USER_INCORRECT:
      return "Ungültige E-Mail-Adresse. Hast du bereits ein Konto bei uns?";
    case AuthErrorType.FAILED_RESET_PASSWORD_LIMIT_EXCEEDED:
      return "Fehler: Dein Passwort wurde vor kurzem geändert. Wende dich bitte an uns unter hi@buildingnovo.com.";
    case AuthErrorType.FAILED_RESET_PASSWORD_EXPIRED_CODE:
      return "Fehler: Dieser Reset-Code wurde bereits verwendet.";
    default:
      return "Es ist leider ein Fehler aufgetreten. Bitte versuche es erneut.";
  }
};

export type AuthError = {
  type: AuthErrorType;
  msg?: string;
};

export type Token = {
  id_token: string;
  access_token: string;
  refresh_token: string;
};

export enum ActionType {
  PASSWORD_CHANGE_REQUIRED,
  CONFIRM_ACCOUNT_REQUIRED,
  MFA_REQUIRED,
}
type LoginAction<ActionType> = {
  actionType: ActionType;
};
type NewPasswordRequired = LoginAction<ActionType.PASSWORD_CHANGE_REQUIRED> & {
  oldPassword: string;
  username: string;
  userAttributes: { [key: string]: string };
  requiredAttributes: string[];
};
type MfaRequired = LoginAction<ActionType.MFA_REQUIRED> & { challengeName: ChallengeName; challengeParameters: unknown };

type AccountConfirmationRequired = LoginAction<ActionType.CONFIRM_ACCOUNT_REQUIRED> & { username: string };

export type ActionNeeded = NewPasswordRequired | MfaRequired | AccountConfirmationRequired;

export type AuthSession = {
  isLoading: boolean;
  token?: Token;
  user?: UserResponse;
  error?: AuthError;
  actionNeeded?: ActionNeeded;
  login: (username: string, password: string) => void;
  logout: () => void;
  clearActionNeeded: () => void;
  updatePasswordOnRequireChange: (newPassword: string, requiredAttributes: { [key: string]: string }) => void;
};

export default function useAuth(): AuthSession {
  const currentCognitoToken = localStorage.getItem(cognitoTokenLSName);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<Token | undefined>(currentCognitoToken ? JSON.parse(currentCognitoToken) : undefined);
  const [actionNeeded, setActionNeeded] = useState<ActionNeeded | undefined>(undefined);
  const [user, setUser] = useState<UserResponse>();
  const [error, setError] = useState<AuthError | undefined>();

  // When login fails due to require password change, this method changes the password using old and
  // new values, and already logs into the session
  const updatePasswordOnRequireChange = async (newPassword: string, requiredAttributes: { [key: string]: string }) => {
    if (actionNeeded?.actionType != ActionType.PASSWORD_CHANGE_REQUIRED) {
      setError({ type: AuthErrorType.MISSING_MANDATORY_PASSWORD_CHANGE_DATA });
      return;
    }
    setIsLoading(true);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: actionNeeded.username });
    await new Promise((resolve, reject) => {
      user.authenticateUser(new AuthenticationDetails({ Username: actionNeeded.username, Password: actionNeeded.oldPassword }), {
        onFailure: (err) => {
          setIsLoading(false);
          setError({ type: AuthErrorType.FAILED_AUTHENTICATING });
          reject(err);
        },
        onSuccess: async () => {
          setIsLoading(false);
          setError({ type: AuthErrorType.CURRENT_PASSWORD_IS_CORRECT });
          reject("already changed password");
        },
        newPasswordRequired: (userAttr, requiredAttr) => {
          console.log(JSON.stringify({ userAttr, requiredAttr }));
          user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
            onFailure: (changePswErr) => {
              setIsLoading(false);
              setError({ type: AuthErrorType.FAILED_CHANGING_PASSWORD });
              reject(changePswErr);
            },
            onSuccess: (changePswdSucc) => {
              setIsLoading(false);
              setToken(toLocalStorageToken(changePswdSucc));
              setActionNeeded(undefined);
              resolve(changePswdSucc);
            },
          });
        },
      });
    }).catch((err) => {
      console.log(JSON.stringify(err));
    });
  };

  const login = async (username: string, password: string) => {
    clearActionNeeded();
    setIsLoading(true);
    setError(undefined);
    const user = new CognitoUser({ Pool: userPool, Username: username });

    await new Promise((resolve: (val: CognitoUserSession) => void, reject) => {
      user.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
        onFailure: (err) => {
          setIsLoading(false);
          switch (err.name) {
            case "UserNotConfirmedException":
              setActionNeeded({ actionType: ActionType.CONFIRM_ACCOUNT_REQUIRED, username });
              break;
            case "NotAuthorizedException":
            case "UserNotFoundException":
              setError({ type: AuthErrorType.WRONG_CREDENTIALS });
              break;
            default:
              setError({ type: AuthErrorType.FAILED_AUTHENTICATING });
              break;
          }

          reject(err);
        },
        onSuccess: async (rslt) => {
          setIsLoading(false);
          setToken(toLocalStorageToken(rslt));
          resolve(rslt);
        },
        newPasswordRequired: (userAttributes: { [key: string]: string }, requiredAttributes: string[]) => {
          setIsLoading(false);
          setActionNeeded({ actionType: ActionType.PASSWORD_CHANGE_REQUIRED, userAttributes, requiredAttributes, oldPassword: password, username });
        },
        mfaRequired: (challengeName: ChallengeName, challengeParameters: unknown) => {
          setIsLoading(false);
          setActionNeeded({ actionType: ActionType.MFA_REQUIRED, challengeName, challengeParameters });
        },
      });
    }).catch((err) => {
      console.log(JSON.stringify(err));
    });
  };

  const clearActionNeeded = () => {
    setActionNeeded(undefined);
  };

  const logout = useCallback(() => {
    clearActionNeeded();
    setError(undefined);
    setUser(undefined);
    setToken(undefined);
  }, []);

  const populateUserInfo = useCallback(
    async (token: Token) => {
      setIsLoading(true);
      try {
        console.log("fetching user data");
        const { data } = await fetchUser(token);
        setUser(data);
        Smartlook.identify(data.id, { name: data.name, email: data.email, tenant: data.tenant?.name ?? "", roles: JSON.stringify(data.groups ?? []) });
      } catch (e) {
        console.log(`failed getting user info: ${JSON.stringify(e)}`);
        if (e.status == 401) {
          logout();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [logout],
  );

  useEffect(() => {
    if (!token) {
      console.log("no user session");
      localStorage.removeItem(cognitoTokenLSName);
      setIsLoading(false);
      return;
    }
    console.log("user session found");
    localStorage.setItem(cognitoTokenLSName, JSON.stringify(token));
    populateUserInfo(token);
  }, [populateUserInfo, token]);

  return { token, user, isLoading, error, actionNeeded, login, logout, clearActionNeeded, updatePasswordOnRequireChange };
}

function toLocalStorageToken(changePswdSucc: CognitoUserSession) {
  return {
    id_token: changePswdSucc.getIdToken().getJwtToken(),
    access_token: changePswdSucc.getAccessToken().getJwtToken(),
    refresh_token: changePswdSucc.getRefreshToken().getToken(),
  };
}

async function fetchUser(token: Token) {
  const res: { data: UserResponse; status: number } = await axios.request({
    method: "GET",
    baseURL: `${API_ENDPOINT}`,
    headers: {
      Authorization: `Bearer ${token.id_token}`,
    },
  });
  return res;
}
