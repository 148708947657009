import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IntroScreen from "./IntroScreen";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Steps } from "./utils";
import ConsentSnackbar from "../Consent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useImage from "../../../../hooks/useImage";
import GeneralInfoScreen from "./GeneralInfoScreen";
import OuterWallsScreen from "./OuterWallsScreen";
import AtticScreen from "./AtticScreen";
import BasementScreen from "./BasementScreen";
import DoorsAndWindowsScreen from "./DoorsAndWindowsScreen";
import HotWaterAndVentilationScreen from "./HotWaterAndVentilationScreen";
import CustomerPreferencesScreen from "./CustomerPreferencesScreen";
import FinalScreen from "./FinalScreen";
import PhotoUploadScreen from "./PhotoUploadScreen";
import PlansAndDocumentsUploadScreen from "./PlansAndDocumentsUploadScreen";
import Smartlook from "smartlook-client";
import { useEffect } from "react";
import FloorsScreen from "./FloorsScreen";
import PhotovoltaicsScreen from "./PhotovoltaicsScreen";

export type ScreenProps = {
  prev: () => void;
  next: () => void;
};

export default function RenovationsFunnel() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId") ?? undefined;

  useEffect(() => {
    if (leadId) {
      Smartlook.identify(leadId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    switch (hash) {
      case Steps.start:
        navigate(Steps.general);
        break;
      case Steps.general:
        navigate(Steps.floors);
        break;
      case Steps.floors:
        navigate(Steps.outerWalls);
        break;
      case Steps.outerWalls:
        navigate(Steps.attic);
        break;
      case Steps.attic:
        navigate(Steps.basement);
        break;
      case Steps.basement:
        navigate(Steps.doorsWindows);
        break;
      case Steps.doorsWindows:
        navigate(Steps.hotWaterVentilation);
        break;
      case Steps.hotWaterVentilation:
        navigate(Steps.photovoltaics);
        break;
      case Steps.photovoltaics:
        navigate(Steps.customerPreferences);
        break;
      case Steps.customerPreferences:
        navigate(Steps.photoUpload);
        break;
      case Steps.photoUpload:
        navigate(Steps.plansAndDocumentsUpload);
        break;
      case Steps.plansAndDocumentsUpload:
        navigate(Steps.final);
        break;
      default:
        navigate(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.general:
        navigate(Steps.start);
        break;
      case Steps.floors:
        navigate(Steps.general);
        break;
      case Steps.outerWalls:
        navigate(Steps.floors);
        break;
      case Steps.attic:
        navigate(Steps.outerWalls);
        break;
      case Steps.basement:
        navigate(Steps.attic);
        break;
      case Steps.doorsWindows:
        navigate(Steps.basement);
        break;
      case Steps.hotWaterVentilation:
        navigate(Steps.doorsWindows);
        break;
      case Steps.photovoltaics:
        navigate(Steps.hotWaterVentilation);
        break;
      case Steps.customerPreferences:
        navigate(Steps.photovoltaics);
        break;
      case Steps.photoUpload:
        navigate(Steps.customerPreferences);
        break;
      case Steps.plansAndDocumentsUpload:
        navigate(Steps.photoUpload);
        break;
      default:
        navigate(Steps.start);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            maxWidth: "lg",
            mx: "auto",
            bgcolor: "background.default",
            px: { xs: 0, lg: 8 },
            pb: { xs: 0, lg: 4 },
          }}
        >
          <Title step={hash} />
          {hash === Steps.start && <IntroScreen prev={prev} next={next} />}
          {hash === Steps.general && <GeneralInfoScreen prev={prev} next={next} />}
          {hash === Steps.floors && <FloorsScreen prev={prev} next={next} />}
          {hash === Steps.outerWalls && <OuterWallsScreen prev={prev} next={next} />}
          {hash === Steps.attic && <AtticScreen prev={prev} next={next} />}
          {hash === Steps.basement && <BasementScreen prev={prev} next={next} />}
          {hash === Steps.doorsWindows && <DoorsAndWindowsScreen prev={prev} next={next} />}
          {hash === Steps.hotWaterVentilation && <HotWaterAndVentilationScreen prev={prev} next={next} />}
          {hash === Steps.photovoltaics && <PhotovoltaicsScreen prev={prev} next={next} />}
          {hash === Steps.customerPreferences && <CustomerPreferencesScreen prev={prev} next={next} />}
          {hash === Steps.photoUpload && <PhotoUploadScreen prev={prev} next={next} />}
          {hash === Steps.plansAndDocumentsUpload && <PlansAndDocumentsUploadScreen prev={prev} next={next} />}
          {hash === Steps.final && <FinalScreen prev={prev} next={next} />}
        </Stack>
      </Box>
      <ConsentSnackbar smartlookId='5abe1187b106b0cb2e77b582a599550690eca13f' metricName='Sanierungscheck' />
    </Box>
  );
}

function Title({ step }: { step: string }) {
  const kfwBafa = useImage("kfw-bafa.png");
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      sx={{
        mx: 10,
        mt: 5,
      }}
    >
      {step === Steps.start && (
        <Grid size={12}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: "fontWeightBold",
              lineHeight: 1.4,
              pb: 0,
            }}
          >
            Welcome back to part 2 of the ISFP
            <br />
            Have your house documents at hand
          </Typography>
        </Grid>
      )}
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Sponsored by</Typography>
          <img src={kfwBafa.image} height={24} style={{ alignSelf: "center" }} />
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Over 90% interesting metrics</Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>A 100% safe investment</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
