import { Grow, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ScreenProps } from "./RenovationsFunnel";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import FormScreen from "../FormScreen";

export default function PhotovoltaicsScreen({ prev, next }: ScreenProps) {
  const [pvInstalled, setPvInstalled] = useState("");
  const [fullfeed, setFullfeed] = useState("");
  const [batteryInstalled, setBatteryInstalled] = useState("");
  const [solarInstalled, setSolarInstalled] = useState("");

  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 8: </strong>Photovoltaics
          </Typography>
        </Grid>
        <Grid size={12}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography flexGrow={1} maxWidth='50%'>
              Do you have photovoltaic panels installed?
            </Typography>
            <ToggleButtonGroup size='small' exclusive value={pvInstalled} onChange={(event, value) => setPvInstalled(value)} sx={{ flexGrow: 1 }}>
              <ToggleButton value='yes'>Yes</ToggleButton>
              <ToggleButton value='no'>No</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        {pvInstalled === "no" && (
          <Grid size={12}>
            <Grow in timeout={800}>
              <Stack direction='row' alignItems='baseline' spacing={2}>
                <Typography flexGrow={1} maxWidth='50%'>
                  Do you wish to have PV panels installed?
                </Typography>
                <ToggleButtonGroup size='small' exclusive value={solarInstalled} onChange={(event, value) => setSolarInstalled(value)} sx={{ flexGrow: 1 }}>
                  <ToggleButton value='yes'>Yes</ToggleButton>
                  <ToggleButton value='no'>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grow>
          </Grid>
        )}
        {pvInstalled === "yes" && (
          <>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-panels-capacity-input'
                  label='Panels capacity'
                  required={true}
                  type='number'
                  slotProps={{
                    htmlInput: {
                      min: 1,
                      max: 25,
                    },
                    input: {
                      endAdornment: <InputAdornment position='end'>kWh</InputAdornment>,
                    },
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-panels-year-input'
                  label='Panels installation year'
                  required={true}
                  type='number'
                  slotProps={{
                    htmlInput: {
                      min: 2000,
                      max: new Date().getFullYear(),
                    },
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%'>
                    Is your system fullfeed?
                  </Typography>
                  <ToggleButtonGroup size='small' exclusive value={fullfeed} onChange={(event, value) => setFullfeed(value)} sx={{ flexGrow: 1 }}>
                    <ToggleButton value='yes'>Yes</ToggleButton>
                    <ToggleButton value='no'>No</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
            <Grid size={12}>
              <Grow in timeout={800}>
                <Stack direction='row' alignItems='baseline' spacing={2}>
                  <Typography flexGrow={1} maxWidth='50%'>
                    Does it include a battery?
                  </Typography>
                  <ToggleButtonGroup
                    size='small'
                    exclusive
                    value={batteryInstalled}
                    onChange={(event, value) => setBatteryInstalled(value)}
                    sx={{ flexGrow: 1 }}
                  >
                    <ToggleButton value='yes'>Yes</ToggleButton>
                    <ToggleButton value='no'>No</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grow>
            </Grid>
          </>
        )}
        {pvInstalled === "yes" && batteryInstalled === "yes" && (
          <>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-battery-capacity-input'
                  label='Battery capacity'
                  required={true}
                  type='number'
                  inputProps={{ min: 1, max: 25 }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>kWh</InputAdornment>,
                  }}
                />
              </Grow>
            </Grid>
            <Grid size={6}>
              <Grow in timeout={800}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='pv-battery-year-input'
                  label='Battery installation year'
                  required={true}
                  type='number'
                  inputProps={{ min: 2000, max: new Date().getFullYear() }}
                />
              </Grow>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Photovoltaics' progressValue={66} content={content} />;
}
