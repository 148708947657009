import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, Collapse, IconButton, TableSortLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDate } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { IsfpProcess, IsfpProcessStatus } from "../../../types/cockpit/types";
import IsfpProcessDetails from "./IsfpProcessDetails";

type Order = "asc" | "desc";
type OrderBy = keyof IsfpProcess;

export interface IsfpWorkflowTableProps {
  isLoading: boolean;
  error?: string | string[];
  isfpWorkflows?: IsfpProcess[];
  onChange: (process: IsfpProcess) => void;
}

export function IsfpProcessTable({ isLoading, error, isfpWorkflows, onChange }: IsfpWorkflowTableProps) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("status");
  const [sortedRows, setSortedRows] = useState<IsfpProcess[]>([]);

  useEffect(() => {
    if (!isfpWorkflows) return;
    const sorted = [...isfpWorkflows.sort(isfpWorkflowsSorter(orderBy, order))];
    setSortedRows(sorted);
  }, [isfpWorkflows, order, orderBy]);

  if (isfpWorkflows && isfpWorkflows?.length < pageSize * page) {
    setPage(0);
    return null;
  }
  const isfpWorkflowsSorter = (property: keyof IsfpProcess, order: Order) => {
    return (a: IsfpProcess, b: IsfpProcess) => {
      const inverter = order === "asc" ? 1 : -1;
      const left = a[property];
      const right = b[property];
      if (!left || !right) return 0;
      if (left < right) {
        return -1 * inverter;
      } else if (left > right) {
        return 1 * inverter;
      } else {
        return 0;
      }
    };
  };

  const handleOrderChange = (newOrder: OrderBy) => {
    if (newOrder === orderBy) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(newOrder);
    }
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = Number.parseInt(event.target.value);
    setPageSize(newPageSize);
    setPage(0);
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    setPage(page);
  };

  if (isLoading || !isfpWorkflows) {
    return <LoadingData />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }
  if (isfpWorkflows?.length === 0) {
    return <EmptyTable />;
  }
  return (
    <Table aria-label='collapsible table' sx={{ "& .MuiTableCell-root": { border: "none" } }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === "leadName"}
              direction={order}
              onClick={() => {
                handleOrderChange("leadName");
              }}
            >
              Name
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "leadEmail"}
              direction={order}
              onClick={() => {
                handleOrderChange("leadEmail");
              }}
            >
              E-Mail
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "referrerEmail"}
              direction={order}
              onClick={() => {
                handleOrderChange("referrerEmail");
              }}
            >
              Berater E-Mail
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "status"}
              direction={order}
              onClick={() => {
                handleOrderChange("status");
              }}
            >
              Status
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === "updatedAt"}
              direction={order}
              onClick={() => {
                handleOrderChange("updatedAt");
              }}
            >
              Zulezt aktualisiert am
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{sortedRows?.slice(page * pageSize, (page + 1) * pageSize).map((row) => <Row key={row.leadId} row={row} onChange={onChange} />)}</TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handlePageSizeChange}
            component='td'
            count={isfpWorkflows?.length || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            colSpan={5}
            labelRowsPerPage='Einträge pro Seite'
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

function LoadingData() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Stack spacing={2}>
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
        <Skeleton variant='rectangular' width={"100%"} height={75} animation='wave' />
      </Stack>
    </Box>
  );
}

function LoadingError({ error }: { error: string | string[] }) {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>{error}</Typography>
    </Box>
  );
}

function EmptyTable() {
  return (
    <Box sx={{ flexGrow: 6, minHeight: 800, width: "100%", pt: 4 }}>
      <Typography>Keine Ergebnisse gefunden</Typography>
    </Box>
  );
}

function Row({ row, onChange }: { row: IsfpProcess; onChange: (process: IsfpProcess) => void }) {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow
        sx={{
          cursor: "pointer",
          height: 75,
          "& .MuiTableCell-root": {
            position: "relative",
            bottom: 0,
            bgcolor: "background.default",
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell component='th' scope='row' sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.leadName}
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }} data-cy={`lead-row-${row.leadEmail}`}>
          {row.leadEmail}
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>{row.referrerEmail}</TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          <Chip label={row.status ? IsfpProcessStatus.toString(row.status) : "Unbekannt"} />
        </TableCell>
        <TableCell sx={{ fontWeight: open ? "fontWeightBold" : "fontWeightRegular" }}>
          {row.updatedAt ? formatDate(row.updatedAt, "dd.MM.yyyy") : "-"}
        </TableCell>
        <TableCell align='right'>
          <IconButton data-cy={`lead-row-expand-btn-${row.leadEmail}`} aria-label='expand row' size='small'>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        data-cy={`lead-row-expanded-${row.leadEmail}`}
        sx={{
          borderSpacing: 0,
          bgcolor: "#FBFBFB",
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <IsfpProcessDetails row={row} onChange={onChange} />
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow sx={{ height: "1rem" }} />
    </Fragment>
  );
}
