import Grid from "@mui/material/Grid2";
import { ScreenProps } from "./RenovationsFunnel";
import { Checkbox, FormControl, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import useImage from "../../../../hooks/useImage";
import FormScreen from "../FormScreen";
import { useState } from "react";

export default function GeneralInfoScreen({ prev, next }: ScreenProps) {
  const [expansionPlans, setExpansionPlans] = useState<string>("");
  const content = () => {
    return (
      <Grid
        container
        spacing={{
          xs: 2,
          lg: 4,
        }}
        sx={{
          ml: { lg: 4 },
          px: { xs: 2 },
          mx: { xs: 0, lg: "inherit" },
        }}
      >
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant='body1'>
            <strong>Screen 1: </strong>General House information
          </Typography>
        </Grid>
        <Grid size={12}>
          <TypeSelector />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField fullWidth variant='outlined' id='house-units-input' label='House units' required={true} type='number' inputProps={{ min: 1, max: 20 }} />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Stack direction='row' alignItems='baseline' spacing={2}>
            <Typography>Do you already have expansion plans?</Typography>
            <ToggleButtonGroup size='small' exclusive value={expansionPlans} onChange={(event, value) => setExpansionPlans(value)}>
              <ToggleButton value='yes'>Yes</ToggleButton>
              <ToggleButton value='no'>No</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return <FormScreen prev={prev} next={next} progressLabel='Jetzt Starten!' progressValue={8} content={content} />;
}

function TypeSelector() {
  const detached = useImage("Detached.png");
  const semi = useImage("Semi.png");
  const terraced = useImage("Terraced.png");
  return (
    <FormControl fullWidth>
      <Grid
        container
        flexGrow={1}
        spacing={{
          xs: 2,
          lg: 4,
        }}
      >
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={detached.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Detached house
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction='row' alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={semi.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Semidetached
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }}>
          <Stack component={Paper} direction={"row"} alignItems='center' sx={{ p: { xs: 1, md: 2 }, cursor: "pointer", height: "100%" }}>
            <Stack alignItems={"start"} spacing={1}>
              <img src={terraced.image} height={40} style={{ alignSelf: "center" }} />
              <Typography variant='body1' sx={{ fontWeight: "fontWeightMedium", fontSize: 11, alignSelf: "center" }}>
                Terraced house
              </Typography>
            </Stack>
            <Checkbox sx={{ flexGrow: 1, "&.Mui-checked": { color: "text.primary" } }} />
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
}
