import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";
import Sophia from "./Sophia";
import ProgressBar from "./ProgressBar";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonWithSpinner from "../../../components/ButtonWithSpinner";

export type ScreenProps = {
  prev: () => void;
  next: () => void;
  isUpdateRunning?: boolean;
};

export type FormScreenProps = ScreenProps & {
  content: React.FC;
  progressLabel?: string;
  progressValue?: number;
  helpContent?: React.FC;
};

export default function FormScreen({
  prev,
  next,
  progressLabel = "Jetz Starten!",
  progressValue = 5,
  content,
  helpContent,
  isUpdateRunning = false,
  ...props
}: FormScreenProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);

  const renderDesktopHelp = () => {
    if (isMobile) {
      return;
    }
    return (
      <>
        <Grid sx={{ px: 0 }}>
          <Divider orientation='vertical' />
        </Grid>
        <Grid sx={{ flexGrow: 1 }}>
          <Sophia content={helpContent} />
        </Grid>
      </>
    );
  };

  const renderMobileHelp = () => {
    const toggleOpen = () => {
      setOpen(!open);
    };

    if (isDesktop) {
      return;
    }
    return (
      <Stack sx={{ mt: 2, px: 3 }}>
        <Link
          variant='caption'
          onClick={toggleOpen}
          sx={{
            color: "text.primary",
            textDecorationColor: "rgba(0, 0, 0, 0.87)",
            fontWeight: "fontWeightMedium",
          }}
        >
          Warum benötigen wir diese Daten?
        </Link>
        <Dialog open={open} onClose={toggleOpen} fullScreen>
          <DialogTitle>
            <IconButton sx={{ float: "inline-end", top: "-4px" }} onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Sophia content={helpContent} />
          </DialogContent>
        </Dialog>
      </Stack>
    );
  };

  return (
    <Grid
      container
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      columnSpacing={{
        xs: 0,
        lg: 8,
      }}
      sx={{
        mb: 4,
        maxWidth: "100%",
      }}
    >
      <Grid size={12}>
        <ProgressBar label={progressLabel} value={progressValue} />
      </Grid>
      <Grid size={{ xs: 12, lg: 8 }}>
        <Stack>
          {content(props)}
          {renderMobileHelp()}
          <Stack
            direction={"row"}
            justifyContent='space-between'
            sx={{
              mt: {
                xs: 4,
                lg: 8,
              },
              px: 3,
            }}
          >
            <Button variant='text' color='secondary' onClick={prev}>
              Zurück
            </Button>
            <ButtonWithSpinner variant='contained' loading={isUpdateRunning} data-cy='isfp-funnel-next-btn' onClick={next} label={"Weiter"} />
          </Stack>
        </Stack>
      </Grid>
      {renderDesktopHelp()}
    </Grid>
  );
}
