import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NovoLogo from "../../../../components/common/NovoLogo";
import CssBaseline from "@mui/material/CssBaseline";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import useImage from "../../../../hooks/useImage";
import useUpsertSchnellcheck from "../../../../hooks/useUpsertSchnellcheck";
import useResumeSchnellcheck from "../../../../hooks/useResumeSchnellcheck";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";
import Screen4 from "./Screen4";
import Screen5 from "./Screen5";
import { ConfirmationScreen } from "./ConfirmationScreen";
import { AnalysisReport, SchnellcheckSource, UpsertQuestionnaireRequest } from "../../../../types/Schnellcheck";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Smartlook from "smartlook-client";
import { Steps } from "../../../../types/cockpit/types";
import { fromHash } from "./utils";
import ConsentSnackbar from "../Consent";
import usePutMetric from "../../../../hooks/usePutMetric";
import { ScreenProps } from "../FormScreen";

export type StatusQuoScreenProps = ScreenProps & {
  schnellcheck: UpsertQuestionnaireRequest;
  setSchnellcheck: Dispatch<SetStateAction<UpsertQuestionnaireRequest>>;
};

export default function StatusQuoFunnel() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [putMetric] = usePutMetric();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const leadId = searchParams.get("leadId") ?? undefined;
  const [schnellcheck, setSchnellcheck] = useState<UpsertQuestionnaireRequest>({ leadId, source: SchnellcheckSource.standalone });
  const [report, setReport] = useState<AnalysisReport | undefined>();
  const { upsertSchnellcheck, upsertSchnellcheckRunning, upsertSchnellcheckResponse } = useUpsertSchnellcheck();
  const { resumeSchnellcheck, toLocal, resuming } = useResumeSchnellcheck();
  const [goNext, setGoNext] = useState(false);

  useEffect(() => {
    resumeSchnellcheck(leadId).then((response) => {
      if (response) {
        setSchnellcheck(response);
        setReport(response.report);
        if (response.answers.houseOpenings) {
          navigate(Steps.report);
        } else if (response.answers.houseInsulation) {
          navigate(Steps.insulation);
        } else if (response.answers.houseHeating) {
          navigate(Steps.heating);
        }
        putMetric("Schnellcheck", "ResumeFunnel");
      }
    });
    if (leadId) {
      Smartlook.identify(leadId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = () => {
    switch (hash) {
      case Steps.start:
        navigate(Steps.heating);
        break;
      case Steps.heating:
        navigate(Steps.insulation);
        break;
      case Steps.insulation:
        navigate(Steps.openings);
        break;
      case Steps.openings:
        navigate(Steps.report);
        break;
      case Steps.report:
        navigate(Steps.confirmation);
        break;
      default:
        navigate(Steps.start);
    }
  };

  const prev = () => {
    switch (hash) {
      case Steps.start:
        navigate(Steps.start);
        break;
      case Steps.heating:
        navigate(Steps.start);
        break;
      case Steps.insulation:
        navigate(Steps.heating);
        break;
      case Steps.openings:
        navigate(Steps.insulation);
        break;
      case Steps.report:
        navigate(Steps.openings);
        break;
      default:
        navigate(Steps.start);
    }
  };

  useEffect(() => {
    if (upsertSchnellcheckResponse && !resuming) {
      setSchnellcheck(upsertSchnellcheckResponse);
      setReport(upsertSchnellcheckResponse.report);
      toLocal(upsertSchnellcheckResponse.id);
      if (goNext) {
        setGoNext(false);
        next();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertSchnellcheckResponse, resuming]);

  useEffect(() => {
    putMetric("Schnellcheck", "RenderStartPage", leadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAndNext = () => {
    setGoNext(true);
    upsertSchnellcheck(schnellcheck);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#f4f5f6",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position='sticky' sx={{ backgroundColor: "background.default", top: 0 }}>
        <Toolbar>
          <NovoLogo height={48} sx={{ flexGrow: 1 }} />
          <NovoLogo fileName='dena-logo.png' height={48} />
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            maxWidth: hash === Steps.confirmation ? "100%" : "lg",
            mx: "auto",
            bgcolor: "background.default",
            px: { xs: 0, lg: hash === Steps.confirmation ? 0 : 8 },
            pb: { xs: 0, lg: hash === Steps.confirmation ? 0 : 4 },
          }}
        >
          {isDesktop && <Title step={fromHash(hash)} />}
          {hash === Steps.start && (
            <Screen1 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.heating && (
            <Screen2 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.insulation && (
            <Screen3 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.openings && (
            <Screen4 schnellcheck={schnellcheck} setSchnellcheck={setSchnellcheck} isUpdateRunning={upsertSchnellcheckRunning} prev={prev} next={saveAndNext} />
          )}
          {hash === Steps.report && (
            <Screen5
              report={report}
              schnellcheck={schnellcheck}
              setSchnellcheck={setSchnellcheck}
              isUpdateRunning={upsertSchnellcheckRunning}
              prev={prev}
              next={saveAndNext}
            />
          )}
          {hash === Steps.confirmation && <ConfirmationScreen />}
        </Stack>
      </Box>
      <ConsentSnackbar smartlookId='794360b37db241e21c97fcde3436bc393f90a19d' metricName='Schnellcheck' />
    </Box>
  );
}

function Title({ step }: { step: Steps }) {
  const kfwBafa = useImage("kfw-bafa.png");

  if (step === Steps.confirmation) {
    return null;
  }

  const renderIntroContent = () => {
    if (step !== Steps.start) {
      return null;
    }
    return (
      <>
        <Grid size={12}>
          <Typography
            variant='h4'
            sx={{
              fontWeight: "fontWeightBold",
              lineHeight: 1.4,
              pb: 0,
            }}
          >
            Unsere Energieberater beraten Sie persönlich.
            <br />
            Kostenlos, unverbindlich, schnell
          </Typography>
        </Grid>
      </>
    );
  };
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={{
        xs: 4,
        lg: 8,
      }}
      sx={{
        mx: 10,
        mt: 5,
      }}
    >
      {renderIntroContent()}
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Förderfähig bei</Typography>
          <img src={kfwBafa.image} height={24} style={{ alignSelf: "center" }} />
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Über 90% zufriedene Kunden</Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "baseline", justifyContent: "center" }}>
          <CheckCircleOutlineIcon sx={{ alignSelf: "center", mr: 2 }} />
          <Typography>Inkl. 100% Rundum-Sorglos-Paket</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
