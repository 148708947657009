import NovoImageUpload from "./NovoImageUpload";

interface MultiImageUploadProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
  label?: string;
  unableToProceed?: boolean;
  required?: boolean;
  error?: boolean;
  direction?: "row" | "column";
  multiple?: boolean;
  maxImages?: number;
  maxSize?: number;
  onChange?: (value: File[]) => void;
}
/**
 *
 * @deprecated use `<NovoImageUpload mutiple />` instead
 */
export default function NovoMultiImageUpload(props: MultiImageUploadProps) {
  return <NovoImageUpload multiple {...props} />;
}
