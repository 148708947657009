import { useTheme, useMediaQuery, Stack, Typography, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import useImage from "../../../../hooks/useImage";
import { Status } from "../../../../types/Schnellcheck";

const colours = ["#f06060", "#ffd24d", "#28bd5a"]; // [StatusColor.red, StatusColor.yellow, StatusColor.green]

export default function HouseStatus({ status }: { status?: Status }) {
  const door = useImage("isfp-icons/door.png");
  const roof = useImage("isfp-icons/roof.png");
  const walls = useImage("isfp-icons/walls.png");
  const basement = useImage("isfp-icons/basement.png");
  const windows = useImage("isfp-icons/window.png");
  const heating = useImage("isfp-icons/heating.png");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid container spacing={0}>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.doors === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Um Wärmeverluste zu verringern, kann eine neue Abdichtung oder ein Tausch der Haustür sinnvoll sein."
            }
          >
            <img
              src={door.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.doors ?? 0] }}
              data-cy='door-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Tür
          </Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.basement === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Je nach Beschaffenheit der Kellerdecke / des Bodens kann eine Dämmung helfen Wärmeverluste zu verringern und die Wohnqualität erheblich zu steigern."
            }
          >
            <img
              src={basement.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.basement ?? 0] }}
              data-cy='basement-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Keller
          </Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.heating === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Ein veraltetes Heizsystem zu überarbeiten oder zu ersetzen kann Ihnen über viele Jahre Ruhe verschaffen und Kosten sparen."
            }
          >
            <img
              src={heating.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.heating ?? 0] }}
              data-cy='heating-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Heizung
          </Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.envelope === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Über die Außenwände geht viel Wärme verloren. Wir prüfen gern, ob und in welchem Umfang eine Dämmung sinnvoll ist."
            }
          >
            <img
              src={walls.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.envelope ?? 0] }}
              data-cy='walls-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Wände
          </Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.roof === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Wärme steigt nach oben. Deshalb geht über das Dach die meiste Wärme verloren. Eine Dämmung hat hier häufig das beste Kosten-Nutzen-Verhältnis."
            }
          >
            <img
              src={roof.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.roof ?? 0] }}
              data-cy='roof-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Dach
          </Typography>
        </Stack>
      </Grid>
      <Grid size={4}>
        <Stack sx={{ p: 2 }}>
          <Tooltip
            title={
              status?.windows === 2
                ? "Wahrscheinlich gibt es hier nichts zu verbessern."
                : "Eine Überarbeitung oder ein Tausch der Fenster kann nicht nur richtig viel Energie einsparen, sondern auch die Behaglichkeit im Haus spürbar verbessern."
            }
          >
            <img
              src={windows.image}
              height={isMobile ? 80 : 120}
              width={isMobile ? 80 : 120}
              style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: colours[status?.windows ?? 0] }}
              data-cy='windows-logo'
            />
          </Tooltip>
          <Typography variant='body1' sx={{ textAlign: "center", mt: 2 }}>
            Fenster
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
